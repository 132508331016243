// eslint-disable-next-line no-restricted-imports
import {Box, Flex, Text} from '@chakra-ui/react';
import {CardImage, CardHeadingSizes} from '../card-utils';
import {CardTopLink} from '../components/card-top-link';
import {CardTopTitle} from '../components/card-top-title';
import {CardTopLogo} from '../components/card-top-logo';

interface CardTopBackgroundProps {
  acentColour: string;
  background: string;
  image: CardImage;
  title: string;
  titleSize: CardHeadingSizes;
  subTitle: string;
  subTitleSize?: string;
  urlSlug?: string;
  isDisabled?: boolean;
}

export const CardTopBackground: React.FC<CardTopBackgroundProps> = ({
  acentColour,
  background,
  image,
  title,
  titleSize,
  subTitle,
  subTitleSize,
  urlSlug,
  isDisabled,
}) => {
  return (
    <Flex
      bg={background}
      align="center"
      grow={1}
      direction="column"
      color="white"
      fontFamily="EB Garamond"
      textAlign="center"
      px="24px"
      py="20px"
      position="relative"
      backgroundSize="cover"
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 70%), url('${image.url}?auto=format&w=400')`,
      }}
    >
      <CardTopLogo color={acentColour} />

      <Box
        width={{base: '176px', '2xl': '200px'}}
        height={{base: '176px', '2xl': '200px'}}
      />

      {title && <CardTopTitle text={title} textSize={titleSize} />}

      {subTitle && (
        <Text
          textStyle="h3_1c"
          mt={{base: '0px', mb: '25px'}}
          mb="16px"
          maxWidth={{base: '242px', md: '100%'}}
          fontStyle="italic"
          fontSize={subTitleSize || undefined}
        >
          {subTitle}
        </Text>
      )}

      {urlSlug && <CardTopLink url={urlSlug} />}

      {isDisabled && (
        <Box
          pos="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          background="white"
          opacity="0.5"
        />
      )}
    </Flex>
  );
};
