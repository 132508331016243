import {Box} from '@chakra-ui/react';
import {Logo} from '../../global/logo';

interface CardTopLogoProps {
  color: string;
}

export const CardTopLogo: React.FC<CardTopLogoProps> = ({color}) => {
  return (
    <Box alignSelf="flex-end">
      <Box width="45px" margin="0 auto">
        <Logo base="white" accent={color} />
      </Box>
    </Box>
  );
};
