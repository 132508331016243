export interface CardImage {
  url: string;
  width?: number;
  height?: number;
  alt?: string;
}

export type CardHeadingSizes = 'small' | 'regular' | 'large';

type SetHeadingSizeReturn = {
  base?: string;
  md?: string;
  lg?: string;
  xl?: string;
  '2xl'?: string;
};

export const getCardHeadingSize = (
  size: CardHeadingSizes,
): SetHeadingSizeReturn => {
  if (size === 'regular') {
    return {base: '32px', '2xl': '40px'};
  }

  if (size === 'large') {
    return {base: '40px', '2xl': '40px'};
  }

  return {base: '28px', '2xl': '32px'};
};
