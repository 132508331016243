// eslint-disable-next-line no-restricted-imports
import {Box, Flex, Image} from '@chakra-ui/react';
import {CardImage, CardHeadingSizes} from '../card-utils';
import {CardTopLink} from '../components/card-top-link';
import {CardTopTitle} from '../components/card-top-title';
import {CardTopLogo} from '../components/card-top-logo';

interface CardTopCenteredProps {
  acentColour: string;
  background: string;
  image: CardImage;
  title: string;
  titleSize: CardHeadingSizes;
  description: string;
  urlSlug?: string;
  isDisabled?: boolean;
  customImage?: any;
}

export const CardTopCentered: React.FC<CardTopCenteredProps> = ({
  acentColour,
  background,
  image,
  title,
  titleSize,
  description,
  urlSlug,
  isDisabled,
  customImage,
}) => {
  return (
    <Flex
      bg={background}
      align="center"
      grow={1}
      direction="column"
      color="white"
      fontFamily="EB Garamond"
      textAlign="center"
      px="24px"
      py="20px"
      position="relative"
    >
      <CardTopLogo color={acentColour} />

      {image && !customImage && (
        <Box width="100%" maxWidth={{base: '176px', '2xl': '200px'}}>
          <Image
            width={176}
            height={176}
            src={`${image.url}?auto=format&w=176&h=176`}
            alt={image.alt || title}
          />
        </Box>
      )}
      {customImage && customImage}

      {title && <CardTopTitle text={title} textSize={titleSize} />}

      {description && (
        <Box
          mt={{base: '0px', mb: '25px'}}
          mb="16px"
          maxWidth={{base: '242px', md: '100%'}}
          sx={{
            strong: {
              fontWeight: '400',
              color: acentColour,
            },
            p: {
              fontSize: {base: '16px', md: '20px', xl: '22px'},
              lineHeight: '120%',
              fontWeight: {base: 'bold', md: '500'},
            },
          }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: description || '',
          }}
        />
      )}

      {urlSlug && <CardTopLink url={urlSlug} />}

      {isDisabled && (
        <Box
          pos="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          background="white"
          opacity="0.5"
        />
      )}
    </Flex>
  );
};
