// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';
import {Box, Flex, Link, Icon} from '@chakra-ui/react';
import {IconArrowRight} from '@tabler/icons';
import mixpanel from 'mixpanel-browser';
import {useRouter} from 'next/router';

interface CardTopLinkProps {
  url: string;
}

export const CardTopLink: React.FC<CardTopLinkProps> = ({url}) => {
  const router = useRouter();
  return (
    <Flex alignItems="center">
      <NextLink passHref href={`/${url}`}>
        <Box
          as="span"
          onClick={() => {
            mixpanel.track('Showpiece Card read more click', {
              assetUrl: url,
              page: router.pathname,
            });
          }}
        >
          <Link
            fontFamily="Spectral"
            fontSize={{base: '18px', md: '22px'}}
            _hover={{
              textDecoration: 'none',
            }}
            sx={{
              '&:hover .border': {
                width: '100%',
              },
              '&:hover .icon': {
                transform: 'translateX(0px)',
              },
            }}
            _before={{
              content: '""',
              cursor: 'inherit',
              display: 'block',
              position: 'absolute',
              top: '0px',
              left: '0px',
              zIndex: 0,
              width: '100%',
              height: '100%',
            }}
          >
            Read the story{' '}
            <Icon
              as={IconArrowRight}
              className="icon"
              transform="translateX(-3px)"
              transition="transform 0.3s ease"
              ml={2}
            />
            <Box
              className="border"
              width="0%"
              height="1px"
              bgColor="white"
              transition="width 0.3s ease"
            />
          </Link>
        </Box>
      </NextLink>
    </Flex>
  );
};
