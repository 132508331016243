// eslint-disable-next-line no-restricted-imports
import {Text, Flex} from '@chakra-ui/react';
import {getCardHeadingSize, CardHeadingSizes} from '../card-utils';

interface CardTopTitleProps {
  textSize: CardHeadingSizes;
  text: string;
}

export const CardTopTitle: React.FC<CardTopTitleProps> = ({textSize, text}) => {
  return (
    <Flex
      align="center"
      justify="center"
      minHeight={{base: '60px', '2xl': '84px'}}
    >
      <Text as="h3" fontWeight="600" fontSize={getCardHeadingSize(textSize)}>
        {text}
      </Text>
    </Flex>
  );
};
