declare global {
  interface Window {
    dataLayer: any;
  }
}

// eslint-disable-next-line no-shadow
export enum EventType {
  PayByCard = 'payByCard',
  BuyPieces = 'buyPieces',
  WhenCanIBuyPieces = 'whenCanIBuyPieces',
  AddToCart = 'addToCart',
  CompletePurchase = 'completePurchase',
  VerificationComplete = 'verificationComplete',
  SignUpComplete = 'signUpComplete',
  BanksyWaitListSignUpSuccess = 'banksyWaitListSignUpSuccess',
}

export const fireGtmDataLayerEvent = (
  eventName: EventType,
  eventParams?: {[key: string]: any},
): void => {
  if (window) {
    let data = {event: eventName};
    if (eventParams) {
      data = {...data, ...eventParams};
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }
};
