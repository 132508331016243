import {Box, Button, Text} from '@chakra-ui/react';
import {useRouter} from 'next/router';
import mixpanel from 'mixpanel-browser';
import {fireGtmDataLayerEvent, EventType} from '../../../utils/gtm-data-layer';

interface GeneralOfferingFooterProps {
  btnTitle: string;
  url?: string;
  title?: string;
  status?: string;
}

export const GeneralOfferingFooter: React.FC<GeneralOfferingFooterProps> = ({
  url,
  btnTitle,
  title,
  status,
}) => {
  const router = useRouter();
  return (
    <Box py={title ? 6 : 8} px={10} textAlign="center">
      {title && (
        <Text textStyle="h7" color="black" mb="21px">
          {title}
        </Text>
      )}
      {url && (
        <Button
          maxW="243px"
          colorScheme="aqua"
          fontSize="15px"
          lineHeight="15px"
          letterSpacing="-0.42px"
          onClick={() => {
            fireGtmDataLayerEvent(EventType.WhenCanIBuyPieces);
            mixpanel.track(`Showpiece card ${status} click`, {
              assetUrl: url,
              page: router.pathname,
            });
            if (url) {
              router.push(url);
            }
          }}
        >
          {btnTitle}
        </Button>
      )}
    </Box>
  );
};
